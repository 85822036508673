<template>
  <div class="contact">
    <div class="topImg"><img alt="" src="../../assets/Rectangle 50.png" /></div>
    <div class="leftImg"><img alt="" src="../../assets/Ellipse 18.png" /></div>
    <div class="title">contact us</div>
    <div class="content">
      <div>
        <div>
          <img src="../../assets/Vector.png" alt="" />
          <span>Phone</span>
          <span>Phone 01:（619）784-1594</span>
        </div>
        <i class="border"></i>
      </div>
      <div>
        <div>
          <img src="../../assets/Vector (1).png" alt="" />
          <span>Address</span>
          <span> oastal Building, Wickham’s Cay II, P. O. Box 2221, Road Town, Tortola, VG1110, British Virgin Islands</span>
        </div>
        <i class="border"></i>
      </div>
      <div>
        <div>
          <img src="../../assets/Vector (2).png" alt="" />
          <span>Email</span>
          <span>business@openc.pro</span>
        </div>
        <i class="border"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "contact",
  data() {
    return {};
  },
  created() {},
  async mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "../../css/public.scss";
.contact {
  position: relative;
}
.leftImg {
  width: 400px;
  height: 410px;
  position: absolute;
  top: 0px;
}
.topImg {
  width: 100%;
  height: 720px;
  position: absolute;
  top: 0;
  left: 0;
}
.title {
  text-align: center;
  font-size: 56px;
  color: #000;
  padding-top: 186px;
}
.content {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 154px 50px 260px 50px;
  & > div {
    flex: 1;
    display: flex;
    .border {
      height: 137px;
      width: 1px;
      background: #ccdbdc;
      float: right;
    }
  }

  & > div:nth-child(1) div {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      height: 50px;
      width: 32px;
    }
    & > span:nth-child(2) {
      font-size: 24px;
      font-weight: 600;
      margin-top: 38px;
      margin-bottom: 10px;
    }
    & > span:nth-child(3) {
      font-size: 20px;
      font-weight: 500;
      opacity: 0.6;
    }
  }
  & > div:nth-child(2) div {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      height: 58px;
      width: 41px;
    }
    & > span:nth-child(2) {
      font-size: 24px;
      font-weight: 600;
      margin-top: 38px;
      margin-bottom: 10px;
    }
    & > span:nth-child(3) {
      font-size: 20px;
      font-weight: 500;
      opacity: 0.6;
	  text-align: center;
    }
  }
  & > div:nth-child(3) div {
    border: none;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      height: 42px;
      width: 56px;
    }
    & > span:nth-child(2) {
      font-size: 24px;
      font-weight: 600;
      margin-top: 38px;
      margin-bottom: 10px;
    }
    & > span:nth-child(3) {
      font-size: 20px;
      font-weight: 500;
      opacity: 0.6;
    }
  }
}
@media screen and (max-device-width: 768px){
  .title{
    font-size: 110px;
  }
  .content{
    display: block;
    .border{
      display: none;
    }
    & > div:nth-child(1) div {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        height: 50*4px;
        width: 32*4px;
      }
      & > span:nth-child(2) {
        font-size: 100px;
        font-weight: 600;
        margin-top: 38px;
        margin-bottom: 10px;
      }
      & > span:nth-child(3) {
        font-size: 60px;
        font-weight: 500;
        opacity: 0.4;
      }
    }
    & > div:nth-child(2) div {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 250px;
      img {
        height: 58*4px;
        width: 41*4px;
      }
      & > span:nth-child(2) {
        font-size: 100px;
        font-weight: 600;
        margin-top: 38px;
        margin-bottom: 10px;
      }
      & > span:nth-child(3) {
        font-size: 60px;
        font-weight: 500;
        text-align: center;
        opacity: 0.4;
      }
    }
    & > div:nth-child(3) div {
      border: none;
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 250px;
      img {
        height: 42*4px;
        width: 56*4px;
      }
      & > span:nth-child(2) {
        font-size: 100px;
        font-weight: 600;
        margin-top: 38px;
        margin-bottom: 10px;
      }
      & > span:nth-child(3) {
        font-size: 60px;
        font-weight: 500;
        opacity: 0.4;
      }
    }
  }
}
</style>
